.react-multi-email {
	background: transparent;
	border-color: rgb(48 48 48 / 100%);
	color: rgb(128 128 128);
	border-radius: 0.25rem;
	font-size: 18px;
}
.react-multi-email > input {
	background: transparent;
}

.react-multi-email:hover {
	border-color: white;
  }
